import { useState, useEffect } from "react";
import "./ScanContainerScreen.scss";
// import axios from "axios";
import VenueNow from "../Images/logo-rectangle-venuenow.png";

import Logo from "../Images/icon-Container.png";
// import Co2Icon from "../Images/icon-carbon-footprint-white.png";

function ScanContainerScreen({ setPackageData, setActivePage }) {
  // const [switchTime, setSwitchTime] = useState(false);
  // const [data, setData] = useState();

  // const token = localStorage.getItem("token");

  // const getData = async () => {
  //   try {
  //     const response = await axios({
  //       method: "get",
  //       url: `https://api.venuenow.tech/analytics/carbon-value-saved/9?dateFrom=2022-01-01&dateTo=2030-12-30`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-access-token": token,
  //       },
  //     });
  //     setData(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  // setTimeout(() => {
  //   setSwitchTime(!switchTime);
  // }, 10000);

  const onBlur = (e) => {
    window.setTimeout(() => e.target.focus(), 0);
  };

  return (
    <div className="scanPackage__base">
      <div className="scanPackage__title">
        <h1 className="scanPackage__title__text">
          Scan package using the reader and place into bin.
        </h1>
        <input
          className="packageScan__image__input"
          autoFocus
          type="text"
          onBlur={onBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setPackageData(e.target.value);
              setActivePage();
            }
          }}
        />
      </div>
      <div className="scanPackage__image">
        {/* {switchTime && (
          <div className="container">
            <h2 className="msg">
              Total Co2 Carbon Footprint Reduced By Westimster School!
            </h2>
            <div className="image">
              <h5 className="image__co2"> {data?.[0].totalGramsSaved}g</h5>
              <img src={Co2Icon} alt="Icon" className="image__img" />
            </div>
          </div>
        )}

        {switchTime === false && (
          <img className="scanPackage__image__logo" src={Logo} alt="Welcome" />
        )} */}

        <img className="scanPackage__image__logo" src={Logo} alt="Welcome" />
      </div>
      <div className="scanPackage__footer">
        <img
          className="scanPackage__footer__logo"
          src={VenueNow}
          alt="Welcome"
        />
      </div>
    </div>
  );
}

export default ScanContainerScreen;
